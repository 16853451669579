import gql from 'graphql-tag';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryFollowingBoardsArgs } from '../../generated/types';

export const GET_FOLLOWING_BOARDS = gql`
  query FollowingBoards {
    followingBoards(sort: { field: "createdAt", direction: DESC }) {
      uuid
      title
      location
      locationStreet
      locationPostCode
      locationCity
      locationCountry
      locationUrl
      locationLatitude
      locationLongitude
      availableDate
      educationLevel
      interestLevel
      seniorityLevel
      workRightEU
      workRightUK
      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
      }
      isOwner
      owner {
        uuid
        email
        nickname
        name
      }
      createdAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }

      permissions

      isDeleted
    }
  }
`;

export const useGetFollowingBoards = (
  options?: QueryHookOptions<Pick<Query, 'followingBoards'>, QueryFollowingBoardsArgs>
) => useQuery<Pick<Query, 'followingBoards'>, QueryFollowingBoardsArgs>(GET_FOLLOWING_BOARDS, options);
