import styled from 'styled-components';

import { media } from '../../../styles/media';

export const BoardsWrapper = styled.div`
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 32px;
  `}

  ${media.from_laptop`
    padding-block-start: 64px;
  `}
`;

export const LineWrapper = styled.div`
  display: flex;
`;

export const LineWrapperIcon = styled.div`
  display: flex;
  transform: translateY(-8px);
`;

export const MaxWidthWrapper = styled.div`
  max-width: 600px;
  margin-inline: auto;
`;
