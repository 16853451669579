import { RestoreFromTrash } from '@styled-icons/material-sharp';
import { Icon, IconProps } from './Icon';

export function UnDeleteIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <RestoreFromTrash />
    </Icon>
  );
}
