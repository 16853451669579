import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateBoardArgs, SortDirection } from '../../generated/types';
import { GET_BOARDS } from '../queries/getBoards';

export const CREATE_BOARD = gql`
  mutation CreateBoard($data: BoardInputData!) {
    createBoard(data: $data) {
      uuid
      title
    }
  }
`;

export const useCreateBoard = (options?: MutationHookOptions<Pick<Mutation, 'createBoard'>, MutationCreateBoardArgs>) =>
  useMutation<Pick<Mutation, 'createBoard'>, MutationCreateBoardArgs>(CREATE_BOARD, {
    awaitRefetchQueries: false,
    refetchQueries: [
      {
        query: GET_BOARDS,
        variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
      },
    ],
    ...options,
  });
