import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Item = styled.li<{ $inactive?: boolean; $isSelected?: boolean }>`
  border-top: solid 3px ${Colors.PrimaryDarkest};
  border-bottom: solid 3px ${Colors.Primary};
  position: relative;
  min-height: 90px;
  padding: 8px 0 0;

  ${media.from_tablet`
    height: 110px;
    padding: 0;
  `}

  transition: opacity 0.2s linear;
  ${({ $inactive }) => $inactive && `opacity: 0.5;`}
  ${({ $isSelected }) => $isSelected && `border-top-color: ${Colors.ContrastDark};`}
  ${({ $isSelected }) => $isSelected && `border-bottom-color: ${Colors.Contrast};`}
`;

export const ItemBelow = styled.div<{
  $isSlidedRight?: boolean;
  $isSlidedLeft?: boolean;
}>`
  position: absolute;
  width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.from_tablet`
    height: 100px;
  `}

  ${({ $isSlidedRight }) =>
    $isSlidedRight &&
    css`
      left: 0;
    `}

  ${({ $isSlidedLeft }) =>
    $isSlidedLeft &&
    css`
      right: 0;
    `}
`;

export const ItemInner = styled.div<{
  $isSlidedRight?: boolean;
  $isSlidedLeft?: boolean;
  $inactive?: boolean;
}>`
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.5s;

  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 0 5px;

  ${media.from_tablet`
    min-height: 100px;
    padding: 0 10px;
  `}

  ${({ $isSlidedRight }) =>
    $isSlidedRight &&
    css`
      left: 180px;
    `}

  ${({ $isSlidedLeft }) =>
    $isSlidedLeft &&
    css`
      left: -180px;
    `}

  ${({ $inactive }) =>
    $inactive &&
    css`
      transition: all 5s;
    `}
`;

export const Content = styled.div`
  flex: 1 1 auto;
  cursor: pointer;
`;

export const AddContent = styled.div`
  flex: 1 1 auto;
`;

export const Actions = styled.div`
  flex: 0 0 70px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  ${media.from_tablet`
    gap: 16px;
  `}
`;

export const IconButton = styled.button`
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;

  &:hover {
    background-color: ${Colors.ContrastUltraLightest};

    svg {
      color: ${Colors.Contrast};
    }
  }
`;
