import { Add } from '@styled-icons/material';

import { TertiaryButton, PrimaryButton } from '../../atoms/Button/Buttons';
import { DeleteIcon, UnDeleteIcon, EditIcon, MailSendIcon } from '../../atoms/Icons';
import { CollectionItemProps, CollectionAddItemProps } from './Collection.types';
import { List, Item, ItemInner, Content, Actions, ItemBelow, IconButton, AddContent } from './Collection.styles';
import { Tooltip } from '@mui/material';

export const CollectionItem = ({
  children,
  onEditClick,
  onDeleteClick,
  onDeleteConfirmClick,
  onViewClick,
  onSendMailClick,
  inactive,
  isSlidedRight,
  isSlidedLeft,
  isArchived,
  isSelected,
  ...otherProps
}: CollectionItemProps) => {
  return (
    <Item $inactive={inactive} $isSelected={isSelected} {...otherProps}>
      <ItemBelow $isSlidedRight={isSlidedRight} $isSlidedLeft={isSlidedLeft}>
        {!inactive && !isArchived && (
          <PrimaryButton onClick={onDeleteConfirmClick} inline busy={inactive}>
            Delete
          </PrimaryButton>
        )}
        {!inactive && isArchived && (
          <PrimaryButton onClick={onDeleteConfirmClick} inline busy={inactive}>
            Restore
          </PrimaryButton>
        )}
        {inactive && isArchived && (
          <PrimaryButton inline busy={inactive}>
            Restoring
          </PrimaryButton>
        )}
        {inactive && !isArchived && (
          <PrimaryButton inline busy={inactive}>
            Deleting
          </PrimaryButton>
        )}
      </ItemBelow>
      <ItemInner $isSlidedRight={isSlidedRight} $isSlidedLeft={isSlidedLeft} $inactive={inactive}>
        <Content onClick={onViewClick}>{children}</Content>
        <Actions>
          {Boolean(onSendMailClick) && (
            <Tooltip title="Invite">
              <IconButton onClick={onSendMailClick} type="button">
                <MailSendIcon size={1.5} />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(onEditClick) && (
            <Tooltip title="Edit">
              <IconButton onClick={onEditClick} type="button">
                <EditIcon size={1.5} />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(onDeleteClick) && (
            <Tooltip title={isArchived ? 'Restore' : 'Archive'}>
              <IconButton onClick={onDeleteClick} type="button">
                {isArchived ? <UnDeleteIcon size={1.5} /> : <DeleteIcon size={1.5} />}
              </IconButton>
            </Tooltip>
          )}
        </Actions>
      </ItemInner>
    </Item>
  );
};

export const CollectionAddItem = ({
  children,
  disabled = false,
  onButtonClick,
  ...otherProps
}: CollectionAddItemProps) => (
  <Item {...otherProps}>
    <ItemInner>
      <AddContent>{children}</AddContent>
      <Actions>
        <TertiaryButton
          disabled={disabled}
          $underline
          iconRight={<Add width="12" height="12" />}
          onClick={onButtonClick}
        >
          Add
        </TertiaryButton>
      </Actions>
    </ItemInner>
  </Item>
);

export const Collection = ({ children }: { children?: React.ReactNode }) => <List>{children}</List>;
