import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast, Id } from 'react-toastify';

import emptyBoardsImage from '../../../assets/vectors/empty.svg';
import emptyArchivedBoardsImage from '../../../assets/vectors/board-no-archive.svg';
import emptyFollowingBoardsImage from '../../../assets/vectors/hire.svg';

import { EmailTemplate, SortDirection } from '../../../generated/types';
import { Urls } from '../../urls';
import { formatDate } from '../../../utils/date';
import { formatUrl } from '../../../utils/url';

import { useSelectionContext, ActionType } from '../../../contexts/SelectionContext';
import { useUserContext } from '../../../contexts/UserContext';
import { useRouter } from '../../../hooks/useRouter';
import { useActiveTab } from '../../../hooks/useActiveTab';

import { GET_BOARD } from '../../../graph/queries/getBoard';
import { useGetBoards } from '../../../graph/queries/getBoards';
import { useGetFollowingBoards } from '../../../graph/queries/getFollowingBoards';
import { useCreateBoard } from '../../../graph/mutations/createBoard';
import { useArchiveBoard } from '../../../graph/mutations/archiveBoard';
import { useInviteUserOnBoard } from '../../../graph/mutations/inviteUserOnBoard';

import { BoardsWrapper, LineWrapper, LineWrapperIcon, MaxWidthWrapper } from './Boards.styles';
import { Collection, CollectionItem, CollectionAddItem } from '../../../components/molecules/Collection/Collection';
import { Copy } from '../../../components/atoms/Typography';
import { TabController, TabPanel } from '../../../components/atoms/Tabs';
import { Placeholder, Size } from '../../../components/atoms/Placeholder/Placeholder';
import { Link, LinkButton } from '../../../components/atoms/Button/Buttons';
import { Skeleton } from '../../../components/atoms/Skeleton';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { InviteUserOnBoardModal } from '../../../components/organisms/InviteUserOnBoardModal';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { GenericErrorAlert, InfoAlert } from '../../../components/atoms/InfoAlert';
import { CrownIcon, StarIcon } from '../../../components/atoms/Icons';
import { Tooltip } from '../../../components/atoms/Tooltip';
import { IconButton } from '../../../components/atoms/Icons/Icon';
import { TinyLoader } from '../../../components/atoms/Loader';
import { FreePlanBlock } from '../../../components/atoms/FreePlanBlock';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { CopyColoredSpan } from '../../../components/atoms/Typography/Copy';

export const Boards: React.FC<{}> = () => {
  const toastId = useRef<Id | null>(null);
  const [activeTab, setActiveTab] = useActiveTab('active_boards');
  const { navigate } = useRouter();
  const user = useUserContext();
  const [isVisible, setIsVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');
  const { data: { boards = [] } = {}, loading } = useGetBoards({
    variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
    fetchPolicy: 'cache-first', // Used for first execution
  });
  const { data: { boards: archivedBoards = [] } = {} } = useGetBoards({
    variables: { where: { isDeleted: true }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
    fetchPolicy: 'cache-first', // Used for first execution
  });
  const { data: { followingBoards = [] } = {} } = useGetFollowingBoards({
    fetchPolicy: 'cache-first', // Used for first execution
  });

  const [createBoard, createBoardState] = useCreateBoard();
  const [archiveBoard, archiveBoardState] = useArchiveBoard();
  const [inviteUserOnBoard] = useInviteUserOnBoard();
  const selection = useSelectionContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleAddBoard(event: React.ChangeEvent<HTMLElement>) {
    event.preventDefault();
    toastId.current = toast.loading('Creating a new board...', { autoClose: false });

    try {
      const result = await createBoard({
        variables: {
          data: {
            title: 'New - Jobs Board',
          },
        },
      });

      if (result.data?.createBoard?.uuid) {
        selection?.dispatch({
          type: ActionType.SELECT_BOARD,
          payload: result.data?.createBoard?.uuid,
        });
      }
      toastId.current && toast.dismiss(toastId.current);
      toast.success('Your new Jobs Board has been created successfully.');
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new Jobs Board at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_BOARDS_REACHED') {
        message =
          'You have reached the maximum number of Jobs Boards allowed for your current plan. Archive some of your existing Jobs Boards to create a new one.';
      }

      toastId.current && toast.dismiss(toastId.current);
      toast.error(message, {
        autoClose: 7000,
      });
    }
  }

  async function handleInviteClick(boardUuid: string) {
    selection?.dispatch({
      type: ActionType.SELECT_BOARD,
      payload: boardUuid,
    });
    setIsVisible(true);
  }

  async function handleEditBoardClick(userUuid: string, boardUuid: string) {
    selection?.dispatch({
      type: ActionType.SELECT_BOARD,
      payload: boardUuid,
    });
    navigate(`/${Urls.Boards}/${formatUrl(Urls.BoardUpdate, { userUuid, boardUuid })}`, {
      state: {
        from: `/${Urls.Boards}#${activeTab}`,
      },
    });
  }

  async function handleViewClick(userUuid: string, boardUuid: string) {
    selection?.dispatch({
      type: ActionType.SELECT_BOARD,
      payload: boardUuid,
    });
    navigate(`/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}`, {
      state: {
        from: `/${Urls.Boards}#${activeTab}`,
      },
    });
  }

  async function handleDeleteClick(boardUuid: string) {
    selection?.dispatch({
      type: ActionType.SELECT_BOARD,
      payload: boardUuid,
    });
    setConfirmDelete(confirmDelete === boardUuid ? '' : boardUuid);
  }

  async function handleDeleteConfirm(boardUuid: string, isDeleted = true) {
    if (!user) return;
    try {
      await archiveBoard({
        variables: {
          userUuid: user.uuid,
          boardUuid,
          isDeleted,
        },
      });
      toast.success(`Your Jobs Board has been successfully ${isDeleted ? 'archived' : 'restored'}.`);
    } catch (error: any) {
      let message = `We apologize, but we were unable to ${
        isDeleted ? 'archive' : 'restore'
      } your Jobs Board at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`;
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'MAX_BOARDS_REACHED') {
        message =
          'You have reached the maximum number of Jobs Boards allowed for your current plan. Archive some of your existing Jobs Boards to restore this one.';
      }
      toast.error(message, {
        autoClose: 7000,
      });
    } finally {
      setConfirmDelete('');
    }
  }

  async function handleInvite({ email, subject, content }: { email: string; subject: string; content: string }) {
    try {
      if (user && selection?.state.selectedBoardUuuid) {
        await inviteUserOnBoard({
          variables: {
            userUuid: user.uuid,
            boardUuid: selection?.state.selectedBoardUuuid,
            data: {
              email,
              emailTemplate: EmailTemplate.InviteBoardAgent,
              subject,
              params: {
                content,
                ctaUrl: `${import.meta.env.VITE_APP_URL}/${Urls.Boards}/${formatUrl(Urls.BoardView, {
                  userUuid: user.uuid,
                  boardUuid: selection?.state.selectedBoardUuuid,
                })}`,
              },
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_BOARD,
              variables: { userUuid: user.uuid, boardUuid: selection?.state.selectedBoardUuuid },
            },
          ],
        });
        toast.success('Agent/Recruiter invited successfully to collaborate on this Jobs Board.');
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to send an invite at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.',
        {
          autoClose: 7000,
        }
      );
    }
    setIsVisible(false);
  }

  function handleInviteCancel() {
    setIsVisible(false);
  }

  const collectionAddItem = createBoardState.loading ? (
    <CollectionAddItem disabled={true} key="progress">
      <TinyLoader />
    </CollectionAddItem>
  ) : (
    <CollectionAddItem key="add" onButtonClick={handleAddBoard}>
      Add a new Jobs Board
    </CollectionAddItem>
  );

  let currentboards = <></>;
  if (loading) {
    currentboards = (
      <Skeleton>
        <rect x="0" y="0" rx="0" ry="0" width="75" height="20" />
        <rect x="80" y="0" rx="0" ry="0" width="75" height="20" />
        <rect x="160" y="0" rx="0" ry="0" width="75" height="20" />
        <rect x="0" y="30" rx="3" ry="3" width="600" height="40" />
        <rect x="0" y="80" rx="3" ry="3" width="600" height="40" />
        <rect x="0" y="130" rx="3" ry="3" width="600" height="40" />
      </Skeleton>
    );
  } else if (boards) {
    currentboards = (
      <>
        <TabController
          headers={[
            { id: 'active_boards', label: 'My Boards' },
            { id: 'following_boards', label: 'Following Boards' },
            { id: 'archived_board', label: 'Archives' },
          ]}
          initialActiveId={activeTab}
          onTabChanged={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <TabPanel $tabId="active_boards">
            <FreePlanBlock>
              <InfoAlert icon={<CrownIcon size={2} />}>
                You can create up to 3 active Jobs Boards. Upgrade to one of our Premium Plans to get unlimited active
                Jobs Boards. <Link to="/checkout">Learn more...</Link>
              </InfoAlert>
              <Spacer y={16} />
            </FreePlanBlock>
            {boards.length === 0 && (
              <Placeholder
                icon={emptyBoardsImage}
                iconAlt="empty"
                iconSize={Size.LARGE}
                title="No Jobs Board found."
                description={
                  <>
                    {createBoardState.loading ? (
                      <>Creating your new Jobs Board...</>
                    ) : (
                      <>
                        <Copy isBold styleLevel={2} marginBottom={20}>
                          Embark on your hiring journey by creating your own{' '}
                          <CopyColoredSpan>Jobs Board</CopyColoredSpan> today.
                        </Copy>
                        <LinkButton onClick={handleAddBoard}>Create a Jobs Board to get started</LinkButton>
                      </>
                    )}
                  </>
                }
              />
            )}
            {boards.length > 0 && (
              <Collection>
                {boards.map(({ owner, uuid, title, location, isNew, createdAt, updatedAt }) => (
                  <CollectionItem
                    key={uuid}
                    onEditClick={() => handleEditBoardClick(owner.uuid, uuid)}
                    onViewClick={() => handleViewClick(owner.uuid, uuid)}
                    onSendMailClick={() => handleInviteClick(uuid)}
                    onDeleteClick={() => handleDeleteClick(uuid)}
                    onDeleteConfirmClick={() => handleDeleteConfirm(uuid)}
                    isSlidedLeft={confirmDelete === uuid}
                    inactive={confirmDelete === uuid && archiveBoardState.loading}
                    isSelected={uuid === selection?.state?.selectedBoardUuuid}
                  >
                    <LineWrapper>
                      <Copy>{title}</Copy>
                      {isNew && (
                        <LineWrapperIcon>
                          <Tooltip
                            title={
                              <IconButton>
                                <StarIcon size={0.6} />
                              </IconButton>
                            }
                          >
                            <Copy styleLevel={1}>New</Copy>
                          </Tooltip>
                        </LineWrapperIcon>
                      )}
                    </LineWrapper>
                    <Copy styleLevel={2}>{location}</Copy>
                    <Copy styleLevel={3}>{formatDate({ date: updatedAt || createdAt, format: 'short' })}</Copy>
                  </CollectionItem>
                ))}
                {collectionAddItem}
              </Collection>
            )}
          </TabPanel>
          <TabPanel $tabId="following_boards">
            <ErrorBoundary>
              {followingBoards.length === 0 && (
                <Placeholder
                  icon={emptyFollowingBoardsImage}
                  iconAlt="empty"
                  iconSize={Size.LARGE}
                  title="No one has shared their Jobs Board with you yet."
                  description={
                    <Copy styleLevel={2}>
                      <MaxWidthWrapper>
                        Unlock collaboration like never before! Whether you're a career advisor, mentor, recruiter, or a
                        supportive friend, <CopyColoredSpan>JobsBoard.io</CopyColoredSpan> empowers you to seamlessly
                        collaborate with others on their exciting job search journey.
                      </MaxWidthWrapper>
                    </Copy>
                  }
                />
              )}
              <Collection>
                {followingBoards.map(({ owner, uuid, title, location, createdAt, updatedAt }) => (
                  <CollectionItem key={uuid} onViewClick={() => handleViewClick(owner.uuid, uuid)}>
                    <Copy>
                      {owner.name} - {owner.email} - {title}
                    </Copy>
                    <Copy styleLevel={2}>{location}</Copy>
                    <Copy styleLevel={3}>{formatDate({ date: updatedAt || createdAt, format: 'short' })}</Copy>
                  </CollectionItem>
                ))}
              </Collection>
            </ErrorBoundary>
          </TabPanel>
          <TabPanel $tabId="archived_board">
            {archivedBoards.length === 0 && (
              <Placeholder
                icon={emptyArchivedBoardsImage}
                iconAlt="empty"
                iconSize={Size.LARGE}
                title="No Archive"
                description={<></>}
              />
            )}
            <Collection>
              {archivedBoards.map(({ uuid, title, location, createdAt, updatedAt }) => (
                <CollectionItem
                  key={uuid}
                  onDeleteClick={() => handleDeleteClick(uuid)}
                  onDeleteConfirmClick={() => handleDeleteConfirm(uuid, false)}
                  isSlidedLeft={confirmDelete === uuid}
                  inactive={confirmDelete === uuid && archiveBoardState.loading}
                  isArchived={true}
                  isSelected={uuid === selection?.state?.selectedBoardUuuid}
                >
                  <Copy>{title}</Copy>
                  <Copy styleLevel={2}>{location}</Copy>
                  <Copy styleLevel={3}>{formatDate({ date: updatedAt || createdAt, format: 'short' })}</Copy>
                </CollectionItem>
              ))}
            </Collection>
          </TabPanel>
        </TabController>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
          <InviteUserOnBoardModal handleCancel={handleInviteCancel} handleSubmit={handleInvite} />
        </Modal>
      </>
    );
  } else {
    currentboards = <GenericErrorAlert key="error" />;
  }

  return (
    <>
      <Helmet title="Boards" />
      <BoardsWrapper>{currentboards}</BoardsWrapper>
    </>
  );
};
