import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Board, Mutation, MutationArchiveBoardArgs, SortDirection } from '../../generated/types';
import { GET_BOARDS } from '../queries/getBoards';

export const ARCHIVE_BOARD = gql`
  mutation ArchiveBoard($userUuid: String!, $boardUuid: String!, $isDeleted: Boolean!) {
    archiveBoard(userUuid: $userUuid, boardUuid: $boardUuid, isDeleted: $isDeleted) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveBoard = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveBoard'>, MutationArchiveBoardArgs>
) =>
  useMutation<Pick<Mutation, 'archiveBoard'>, MutationArchiveBoardArgs>(ARCHIVE_BOARD, {
    awaitRefetchQueries: false,
    update(cache, { data: { archiveBoard: { uuid, isDeleted } = {} } }) {
      const activeCache = cache.readQuery({
        query: GET_BOARDS,
        variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
      });
      const activeBoards = activeCache?.boards || [];
      const deletedCache = cache.readQuery({
        query: GET_BOARDS,
        variables: { where: { isDeleted: true }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
      });
      const deletedBoards = deletedCache?.boards || [];

      let updatedDeletedBoards: Board[] = [];
      let updatedActiveBoards: Board[] = [];

      if (isDeleted) {
        const currentBoard = activeBoards?.find((board) => board.uuid === uuid);
        updatedDeletedBoards = [currentBoard, ...deletedBoards];
        updatedActiveBoards = activeBoards?.filter((board) => board.uuid !== uuid);
      } else {
        const currentBoard = deletedBoards?.find((board) => board.uuid === uuid);
        updatedDeletedBoards = deletedBoards?.filter((board) => board.uuid !== uuid);
        updatedActiveBoards = [currentBoard, ...activeBoards];
      }

      const updatedDeletedBoardsKeys = updatedDeletedBoards.map((board) => {
        return {
          __typename: 'Board',
          uuid: board.uuid,
        };
      });
      const updatedActiveBoardsKeys = updatedActiveBoards.map((board) => {
        return {
          __typename: 'Board',
          uuid: board.uuid,
        };
      });

      cache.writeQuery({
        query: GET_BOARDS,
        variables: { where: { isDeleted: true }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
        data: {
          boards: updatedDeletedBoardsKeys,
        },
      });

      cache.writeQuery({
        query: GET_BOARDS,
        variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
        data: {
          boards: updatedActiveBoardsKeys,
        },
      });
    },

    ...options,
  });
